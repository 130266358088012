import { IntroAnimation } from '/components/IntroAnimation'
import { ScrollIndicator } from '/components/ScrollIndicator'
import { SiteHeader } from '/components/SiteHeader'
import { Section } from '/components/Section'
import styles from './Page.css'

export function Page({ children, mainNav, language }) {
  return (
    <div className={styles.page}>
      <IntroAnimation layoutClassName={styles.introAnimation} />
      <ScrollIndicator layoutClassName={styles.scrollIndicator} />
      <SiteHeader
        layoutClassName={styles.siteHeader}
        {...{ mainNav, language }}
      />
      {children}
    </div>
  )
}

export function PageSection({ id, children, sectionProps }) {
  const idProps = id
    ? { sectionName: id, id, layoutClassName: styles.sectionWithId }
    : {}
  return <Section {...sectionProps} {...idProps} {...{ children, id }} />
}
