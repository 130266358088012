import styles from './Row.css'

export function Row({ content, description = undefined, layoutClassName = undefined }) {
  return (
    <article className={cx(styles.component, layoutClassName)}>
      {description && <div className={styles.description}>{description}</div> }
      <div className={styles.content}>{content}</div>
    </article>
  )
}
