import styles from './GridTwoColumns.css'

export function GridTwoColumns({
  children,
  layoutClassName = undefined,
  alignCenter = false,
}) {
  return (
    <div
      className={cx(
        styles.component,
        layoutClassName,
        alignCenter && styles.alignCenter
      )}
    >
      {children}
    </div>
  )
}
