import { TextWithHeading } from '/components/TextWithHeading'
import { CenteredContainer } from '/components/CenteredContainer'
import { HeadingXl, HeadingSm } from '/components/Heading'
import { TextMd, TextSm } from '/components/Text'
import { GridTwoColumns } from '/components/GridTwoColumns'
import styles from './Dankwoord.css'

export function Dankwoord({
  heading,
  text,
  person1Heading,
  person1Text,
  person2Heading,
  person2Text,
  person3Heading,
  person3Text,
  person4Heading,
  person4Text,
}) {
  return (
    <CenteredContainer size="xl">
      <article className={styles.component}>
        <TextWithHeading
          heading={<HeadingXl>{heading}</HeadingXl>}
          text={<TextMd>{text}</TextMd>}
        />

        <GridTwoColumns layoutClassName={styles.contactInfo}>
          <TextWithHeading
            heading={<HeadingSm>{person1Heading}</HeadingSm>}
            text={<TextSm>{person1Text}</TextSm>}
          />
          <TextWithHeading
            heading={<HeadingSm>{person2Heading}</HeadingSm>}
            text={<TextSm>{person2Text}</TextSm>}
          />
          <TextWithHeading
            heading={<HeadingSm>{person3Heading}</HeadingSm>}
            text={<TextSm>{person3Text}</TextSm>}
          />
          <TextWithHeading
            heading={<HeadingSm>{person4Heading}</HeadingSm>}
            text={<TextSm>{person4Text}</TextSm>}
          />
        </GridTwoColumns>
      </article>
    </CenteredContainer>
  )
}
