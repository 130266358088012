import { MedewerkersNl, MedewerkersEn } from '/pages/Medewerkers'
import { KlantenNl, KlantenEn } from '/pages/Klanten'
import { NotFound } from '/pages/NotFound'

export default function App({ config, location }) {
  const { pathname } = location

  return (
    pathname.startsWith('/medewerkers/nl') ? <MedewerkersNl /> :
    pathname.startsWith('/medewerkers/en') ? <MedewerkersEn /> :
    pathname.startsWith('/klanten/nl') ? <KlantenNl /> :
    pathname.startsWith('/klanten/en') ? <KlantenEn /> :
    <NotFound />
  )
}
