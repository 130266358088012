import styles from './Heading.css'

export function HeadingXl({ children, h = 1, color = undefined, layoutClassName = undefined }) {
  return (
    <HeadingBase className={cx(styles.componentXl, layoutClassName)} {...{ children, h, color }} />
  )
}

export function HeadingLg({ children, h = 2, color = undefined, layoutClassName = undefined }) {
  return (
    <HeadingBase className={cx(styles.componentLg, layoutClassName)} {...{ children, h, color }} />
  )
}

export function HeadingMd({ children, h = 3, color = undefined, layoutClassName = undefined }) {
  return (
    <HeadingBase className={cx(styles.componentMd, layoutClassName)} {...{ children, h, color }} />
  )
}

export function HeadingSm({ children, h = 4, color = undefined, layoutClassName = undefined }) {
  return (
    <HeadingBase className={cx(styles.componentSm, layoutClassName)} {...{ children, h, color }} />
  )
}

function HeadingBase({ children, className, h, color }) {
  const H = `h${h}`
  return (
    <H {...{ className }} style={{ color }}>
      {children}
    </H>
  )
}
