export function RobaLogo({ layoutClassName = undefined, color = undefined }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={layoutClassName}
      fill={color ? color : 'currentColor'}
      fillRule="nonzero"
      viewBox="0 0 600 133"
    >
      <title>Roba</title>
      <path d="M229.727.871c45.186.6 75.578 26.993 75.578 65.581 0 38.59-30.392 64.981-75.578 65.58H219.33c-45.187 0-75.58-26.391-75.58-65.58 0-39.19 30.393-65.581 75.38-65.581h10.597zM89.175 1.87c33.59 0 53.984 12.596 53.984 39.389 0 22.595-15.796 33.19-33.59 33.19v.602c15.035 2.373 24.002 14.535 27.676 28.736l6.914 27.248H101.77l-5.997-24.994-.089-.362-.186-.717a39.755 39.755 0 00-.096-.353l-.202-.699-.105-.345-.219-.68c-2.991-8.985-9.055-14.64-21.097-14.64h-33.79v42.79H0V1.87h89.175zm312.292 0c31.391 0 48.786 11.598 48.786 34.792 0 18.193-12.596 27.192-25.793 28.79v.601c15.196.2 28.391 10.797 28.391 29.79 0 22.394-16.393 35.192-51.784 35.192h-91.774V1.87h92.174zM600 1.87v129.165h-39.987v-40.99H496.63v40.99h-39.989V68.653c0-39.99 30.191-66.783 71.98-66.783H600zM398.269 79.848h-48.987v20.994h48.987c8.997 0 13.794-3.6 13.794-10.596 0-6.998-4.797-10.398-13.794-10.398zm-173.14-47.186h-1.2c-23.595 0-38.59 13.196-38.59 33.79 0 20.593 14.995 33.791 38.59 33.791h1.2c23.592 0 38.588-13.198 38.588-33.79 0-20.595-14.996-33.79-38.588-33.79zm334.884.8H531.42c-18.795 0-30.39 9.198-33.79 26.593h62.384V33.462zm-474.837-1H39.989v25.793h45.187c10.996 0 16.796-4.2 16.796-12.797 0-8.796-5.8-12.995-16.796-12.995zm310.492-.4h-46.386v20.594h46.386c8.798 0 13.398-3.398 13.398-10.198 0-6.798-4.6-10.395-13.398-10.395z" />
    </svg>
  )
}
