import banner from '/images/banner.jpg?max-width=1320'
import briefpapier from '/images/briefpapier.png?max-width=490'
import brochures from '/images/brochures.png?max-width=450'
import tas from '/images/tas.png?max-width=450'
import schrijfstijl from '/images/schrijfstijl.png?max-width=450'
import vlag from '/images/vlag.png?max-width=320'
import staal from '/images/illustrations/staal.svg'
import steel from '/images/illustrations/steel.svg'
import recycling from '/images/illustrations/recycling.svg'
import rvs from '/images/illustrations/rvs.svg'
import stainless from '/images/illustrations/stainless.svg'
import nonferro from '/images/illustrations/non-ferro.svg'
import nonferrous from '/images/illustrations/non-ferrous.svg'
import maisonNeueExtendedDemi from '/images/illustrations/maison-neue-extended-demi.svg'
import maisonNeueExtendedBold from '/images/illustrations/maison-neue-extended-bold.svg'
import maisonNeueBook from '/images/illustrations/maison-neue-book.svg'
import maisonNeueBold from '/images/illustrations/maison-neue-bold.svg'
import arialRegular from '/images/illustrations/arial-regular.svg'
import arialBold from '/images/illustrations/arial-bold.svg'
import roba from '/images/illustrations/roba.svg'
import payoff from '/images/illustrations/payoff.svg'
import robaPayoff from '/images/illustrations/robaPayoff.svg'
import piramideNLlarge from '/images/illustrations/piramideNLlarge.svg'
import piramideNLsmall from '/images/illustrations/piramideNLsmall.svg'
import piramideENlarge from '/images/illustrations/piramideENlarge.svg'
import piramideENsmall from '/images/illustrations/piramideENsmall.svg'

export const images = {
  banner,
  briefpapier,
  brochures,
  tas,
  schrijfstijl,
  vlag
}

export const illustrations = {
  staal,
  steel,
  recycling,
  rvs,
  stainless,
  nonferro,
  nonferrous,
  maisonNeueExtendedDemi,
  maisonNeueExtendedBold,
  maisonNeueBook,
  maisonNeueBold,
  arialRegular,
  arialBold,
  roba,
  payoff,
  robaPayoff,
  piramideNLlarge,
  piramideNLsmall,
  piramideENlarge,
  piramideENsmall,
}
