import { TextWithHeading } from '/components/TextWithHeading'
import { CenteredContainer } from '/components/CenteredContainer'
import { HeadingMd } from '/components/Heading'
import { TextSm } from '/components/Text'
import { Row } from '/components/Row'

export function NieuweLogo({
  robaLogo,
  robaLogoHeading,
  robaLogoText,
  payoff,
  payoffHeading,
  payoffText,
  robaLogoPayoff,
  robaLogoPayoffHeading,
  robaLogoPayoffText,
}) {
  return (
    <CenteredContainer size="xl">
      <Row
        description={
          <TextWithHeading
            heading={<HeadingMd>{robaLogoHeading}</HeadingMd>}
            text={<TextSm>{robaLogoText}</TextSm>}
          />
        }
        content={<img src={robaLogo} alt="" />}
      />
      <Row
        description={
          <TextWithHeading
            heading={<HeadingMd>{payoffHeading}</HeadingMd>}
            text={<TextSm>{payoffText}</TextSm>}
          />
        }
        content={<img src={payoff} alt="" />}
      />
      <Row
        description={
          <TextWithHeading
            heading={<HeadingMd>{robaLogoPayoffHeading}</HeadingMd>}
            text={<TextSm>{robaLogoPayoffText}</TextSm>}
          />
        }
        content={<img src={robaLogoPayoff} alt="" />}
      />
    </CenteredContainer>
  )
}
