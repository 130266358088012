import { TextWithHeading } from '/components/TextWithHeading'
import { HeadingSm } from '/components/Heading'
import { TextLg } from '/components/Text'
import styles from './Merkverhaal.css'

export function Merkverhaal({ heading, verhaal }) {
  return (
    <div className={styles.component}>
      <TextWithHeading
        layoutClassName={styles.merkverhaalShortCopy}
        heading={<HeadingSm>{heading}</HeadingSm>}
      />

      <TextLg layoutClassName={styles.merkverhaalLongCopy}>{verhaal}</TextLg>
    </div>
  )
}
