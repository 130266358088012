import { HeadingSm } from '/components/Heading'
import styles from './ColorLabel.css'

export function ColorLabel({ label }) {
  return (
    <div className={styles.component}>
      <HeadingSm>{label}</HeadingSm>
    </div>
  )
}
