import { useSpring, animated } from 'react-spring'
import styles from './ScrollIndicator.css'

export function ScrollIndicator({ layoutClassName = undefined }) {
  const [spring, springApi] = useSpring(() => ({
    transform: `scaleX(0)`,
  }))

  React.useEffect(() => {
    const handleScroll = () => {
      const scrollPx = document.documentElement.scrollTop
      const winHeightPx =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight
      const scrolled = scrollPx / winHeightPx

      springApi.start({ transform: `scaleX(${scrolled})` })
    }
    handleScroll()
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => window.removeEventListener('scroll', handleScroll)
  }, [springApi])

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <animated.div style={spring} className={styles.progress} />
    </div>
  )
}
