import styles from './Section.css'

export function Section(
  {
    children,
    layoutClassName = undefined,
    sectionName = undefined,
    className = undefined,
    id = undefined,
    backgroundColor,
    color,
    lightOnDark = false,
  }
) {
  return (
    <section
      {...{ id }}
      data-section={sectionName}
      className={cx(
        styles.component,
        lightOnDark && styles.lightOnDark,
        className,
        layoutClassName
      )}
      style={{ color, backgroundColor }}
    >
      {children}
    </section>
  )
}
