import { useMediaQuery } from '/machinery/useMediaQuery'
import { TextWithHeading } from '/components/TextWithHeading'
import { CenteredContainer } from '/components/CenteredContainer'
import { HeadingXl } from '/components/Heading'
import { TextMd } from '/components/Text'
import color from '/cssGlobal/color.css'
import styles from './Opbouw.css'

export function Opbouw({ heading, text, imageLg, imageSm }) {
  const viewportMd = useMediaQuery('(min-width: 48em)')

  return (
    <CenteredContainer size="xl">
      <article className={styles.component}>
        <TextWithHeading
          layoutClassName={styles.text}
          heading={<HeadingXl color={color.darkGreen}>{heading}</HeadingXl>}
          text={<TextMd>{text}</TextMd>}
        />

        <div className={styles.image}>
          {viewportMd ? (
            <img src={imageLg} alt="large" />
          ) : (
            <img src={imageSm} alt="small" />
          )}

        </div>
      </article>
    </CenteredContainer>
  )
}
