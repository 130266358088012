import { TextWithHeading } from '/components/TextWithHeading'
import { CenteredContainer } from '/components/CenteredContainer'
import { HeadingXl } from '/components/Heading'
import { TextMd } from '/components/Text'
import color from '/cssGlobal/color.css'
import styles from './TextWithImage.css'

export function TextWithImage({ heading, text, image = undefined }) {
  return (
    <CenteredContainer size="xl">
      <article className={styles.component}>
        <TextWithHeading
          layoutClassName={styles.text}
          heading={<HeadingXl color={color.darkGreen}>{heading}</HeadingXl>}
          text={<TextMd>{text}</TextMd>}
        />

        {image && (
          <div className={styles.image}>
            <img src={image} alt="" />
          </div>
        )}
      </article>
    </CenteredContainer>
  )
}
