import { TextWithHeading } from '/components/TextWithHeading'
import { CenteredContainer } from '/components/CenteredContainer'
import { HeadingLg, HeadingMd } from '/components/Heading'
import { TextSm } from '/components/Text'
import { Row } from '/components/Row'
import { GridTwoColumns } from '/components/GridTwoColumns'
import color from '/cssGlobal/color.css'

export function SchrijfstijlVoorbeelden({
  voorbeeldHeading,
  welHeading,
  nietHeading,
  voorbeeldConcreetText,
  concreetHeading,
  concreetWelText,
  concreetNietText,
  voorbeeldPersoonlijk,
  persoonlijkHeading,
  persoonlijkWelText,
  persoonlijkNietText,
  voorbeeldGedreven,
  gedrevenHeading,
  gedrevenWelText,
  gedrevenNietText,
}) {
  return (
    <CenteredContainer size="xl">
      <Row
        description={
          <TextWithHeading
            heading={<HeadingMd>{voorbeeldHeading}</HeadingMd>}
            text={<TextSm>{voorbeeldConcreetText}</TextSm>}
          />
        }
        content={
          <>
            <HeadingLg color={color.darkGreen}>{concreetHeading}</HeadingLg>
            <GridTwoColumns>
              <TextWithHeading
                heading={<HeadingMd>{welHeading}</HeadingMd>}
                text={<TextSm>{concreetWelText}</TextSm>}
              />
              <TextWithHeading
                heading={<HeadingMd>{nietHeading}</HeadingMd>}
                text={<TextSm>{concreetNietText}</TextSm>}
              />
            </GridTwoColumns>
          </>
        }
      />

      <Row
        description={
          <TextWithHeading
            heading={<HeadingMd>{voorbeeldHeading}</HeadingMd>}
            text={<TextSm>{voorbeeldPersoonlijk}</TextSm>}
          />
        }
        content={
          <>
            <HeadingLg color={color.darkGreen}>{persoonlijkHeading}</HeadingLg>
            <GridTwoColumns>
              <TextWithHeading
                heading={<HeadingMd>{welHeading}</HeadingMd>}
                text={<TextSm>{persoonlijkWelText}</TextSm>}
              />
              <TextWithHeading
                heading={<HeadingMd>{nietHeading}</HeadingMd>}
                text={<TextSm>{persoonlijkNietText}</TextSm>}
              />
            </GridTwoColumns>
          </>
        }
      />

      <Row
        description={
          <TextWithHeading
            heading={<HeadingMd>{voorbeeldHeading}</HeadingMd>}
            text={<TextSm>{voorbeeldGedreven}</TextSm>}
          />
        }
        content={
          <>
            <HeadingLg color={color.darkGreen}>{gedrevenHeading}</HeadingLg>
            <GridTwoColumns>
              <TextWithHeading
                heading={<HeadingMd>{welHeading}</HeadingMd>}
                text={<TextSm>{gedrevenWelText}</TextSm>}
              />
              <TextWithHeading
                heading={<HeadingMd>{nietHeading}</HeadingMd>}
                text={<TextSm>{gedrevenNietText}</TextSm>}
              />
            </GridTwoColumns>
          </>
        }
      />
    </CenteredContainer>
  )
}
