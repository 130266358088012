
import { CenteredContainer } from './CenteredContainer'
import { LinkInline } from '/components/Link'
import { MainNav } from './MainNav'
import styles from './SiteHeader.css'

export function SiteHeader({ layoutClassName, mainNav, language }) {
  const { languageUrl, languageText } = language
  return (
    <header className={cx(styles.component, layoutClassName)}>
      <CenteredContainer size='xl'>
        <div className={styles.layout}>
          <div className={styles.languageSwitch}>
            <LinkInline to={languageUrl}>{languageText}</LinkInline>
          </div>
          <MainNav layoutClassName={styles.nav} {...{ mainNav }} />
        </div>
      </CenteredContainer>
    </header>
  )
}
