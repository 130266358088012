import { illustrations } from '/images'
import { TextWithHeading } from '/components/TextWithHeading'
import { CenteredContainer } from '/components/CenteredContainer'
import { HeadingMd } from '/components/Heading'
import { TextSm } from '/components/Text'
import { Row } from '/components/Row'
import { GridTwoColumns } from '/components/GridTwoColumns'

export function Typografie({
  corporateHeading,
  corporateText,
  officeHeading,
  officeText,
}) {
  return (
    <CenteredContainer size="xl">
      <Row
        description={
          <TextWithHeading
            heading={<HeadingMd>{officeHeading}</HeadingMd>}
            text={<TextSm>{officeText}</TextSm>}
          />
        }
        content={
          <GridTwoColumns>
            <img src={illustrations['arialRegular']} alt="" />
            <img src={illustrations['arialBold']} alt="" />
          </GridTwoColumns>
        }
      />
      <Row
        description={
          <TextWithHeading
            heading={<HeadingMd>{corporateHeading}</HeadingMd>}
            text={<TextSm>{corporateText}</TextSm>}
          />
        }
        content={
          <GridTwoColumns>
            <img src={illustrations['maisonNeueExtendedDemi']} alt="" />
            <img src={illustrations['maisonNeueExtendedBold']} alt="" />
            <img src={illustrations['maisonNeueBook']} alt="" />
            <img src={illustrations['maisonNeueBold']} alt="" />
          </GridTwoColumns>
        }
      />
    </CenteredContainer>
  )
}
