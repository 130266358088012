import { CenteredContainer } from './CenteredContainer'
import { LinkBlock } from '/components/Link'
import { RobaLogo } from './RobaLogo'
import styles from './SiteFooter.css'

export function SiteFooter() {
  return (
    <footer className={styles.component}>
      <CenteredContainer size='xl'>
        <div className={styles.layout}>
          <LinkBlock to={'/'}>
            <div className={styles.logoWrapper}>
              <RobaLogo layoutClassName={styles.logo} />
            </div>
          </LinkBlock>
          <p className={styles.copyright}>
            Copyright © {new Date().getFullYear()} Roba Metals
          </p>
        </div>
      </CenteredContainer>
    </footer>
  )
}
