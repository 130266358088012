import styles from './TextWithHeading.css'

export function TextWithHeading({ heading = undefined, text = undefined, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.component, layoutClassName)}>
      {heading && heading}
      {text && text}
    </div>
  )
}
