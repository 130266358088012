import {
  ColorBlockLg,
  ColorBlockMd,
  ColorBlockSm,
} from '/components/ColorBlock'
import color from '/cssGlobal/color.css'

export function ColorBlocks({
  groen,
  wit,
  lichtGrijs,
  middenGrijs,
  donkerGrijs,
  zwart,
  felGroen,
  oranje,
}) {
  return (
    <>
      <ColorBlockLg
        color={color.white}
        backgroundColor={color.darkGreen}
        lightOnDark
        label={groen}
      />
      <ColorBlockLg
        color={color.black}
        backgroundColor={color.white}
        label={wit}
      />
      <ColorBlockLg
        color={color.black}
        backgroundColor={color.lightGrey}
        label={lichtGrijs}
      />
      <ColorBlockMd
        color={color.white}
        backgroundColor={color.midGrey}
        lightOnDark
        label={middenGrijs}
      />
      <ColorBlockMd
        color={color.white}
        backgroundColor={color.darkGrey}
        lightOnDark
        label={donkerGrijs}
      />
      <ColorBlockMd
        color={color.white}
        backgroundColor={color.black}
        lightOnDark
        label={zwart}
      />
      <ColorBlockSm
        color={color.black}
        backgroundColor={color.brightGreen}
        label={felGroen}
      />
      <ColorBlockSm
        color={color.white}
        backgroundColor={color.orange}
        lightOnDark
        label={oranje}
      />
    </>
  )
}
