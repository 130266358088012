import { LinkInline } from '/components/Link'
import styles from './MainNav.css'

export function MainNav({ layoutClassName, mainNav }) {
  return (
    <nav className={cx(styles.component, layoutClassName)}>
      {mainNav.map((item, i) =>
        <div className={styles.item} key={i}>
          <LinkInline to={`#${item}`}>{item}</LinkInline>
        </div>
      )}
    </nav>
  )
}
