import { TextWithHeading } from '/components/TextWithHeading'
import { CenteredContainer } from '/components/CenteredContainer'
import { HeadingXl } from '/components/Heading'
import { TextMd, TextSm } from '/components/Text'
import color from '/cssGlobal/color.css'
import styles from './Marketing.css'

export function Marketing({ heading, lead, text, image }) {
  return (
    <CenteredContainer size="xl">
      <article className={styles.component}>
        <TextWithHeading
          layoutClassName={styles.marketingIntro}
          heading={<HeadingXl color={color.darkGreen}>{heading}</HeadingXl>}
          text={<TextMd>{lead}</TextMd>}
        />
        <TextSm layoutClassName={styles.marketingText}>{text}</TextSm>
        <div className={styles.marketingImage}>
          <img src={image} alt="" />
        </div>
      </article>
    </CenteredContainer>
  )
}
