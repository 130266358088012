import { CenteredContainer } from '/components/CenteredContainer'
import { ColorLabel } from '/components/ColorLabel'
import styles from './ColorBlock.css'

export function ColorBlockLg({ backgroundColor, color, lightOnDark = false, label }) {
  return <ColorBlockBase className={styles.componentLg} {...{ backgroundColor, color, lightOnDark, label }} />
}

export function ColorBlockMd({ backgroundColor, color, lightOnDark = false, label }) {
  return <ColorBlockBase className={styles.componentMd} {...{ backgroundColor, color, lightOnDark, label }} />
}

export function ColorBlockSm({ backgroundColor, color, lightOnDark = false, label }) {
  return <ColorBlockBase className={styles.componentSm} {...{ backgroundColor, color, lightOnDark, label }} />
}

function ColorBlockBase({ className, backgroundColor, color, lightOnDark, label }) {
  return (
    <div
      className={cx(
        className,
        styles.componentBase,
        lightOnDark && styles.lightOnDark,
      )}
      style={{ color, backgroundColor }}
    >
      <CenteredContainer size="xl">
        <ColorLabel {...{ label }} />
      </CenteredContainer>
    </div>
  )
}
