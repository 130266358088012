import styles from './Text.css'

export function TextLg({ children, layoutClassName = undefined }) {
  return <TextBase className={cx(styles.componentLg, layoutClassName)} {...{ children }} />
}

export function TextMd({ children, layoutClassName = undefined }) {
  return <TextBase className={cx(styles.componentMd, layoutClassName)} {...{ children }} />
}

export function TextSm({ children, layoutClassName = undefined }) {
  return <TextBase className={cx(styles.componentSm, layoutClassName)} {...{ children }} />
}

function TextBase({ children, className }) {
  return <div {...{ className }}>{children}</div>
}
