import { images, illustrations } from '/images'
import { RobaLogo } from '/components/RobaLogo'
import { SiteFooter } from '/components/SiteFooter'
import { LinkBlock } from '/components/Link'
import { Banner } from '/components/Banner'
import { Page, PageSection } from './Page'
import { Merkverhaal } from './pageOnly/Merkverhaal'
import { Opbouw } from './pageOnly/Opbouw'
import { NieuweLogo } from './pageOnly/NieuweLogo'
import { KleurGebruik } from './pageOnly/KleurGebruik'
import { ColorBlocks } from './pageOnly/ColorBlocks'
import { Iconografie } from './pageOnly/Iconografie'
import { Typografie } from './pageOnly/Typografie'
import { TextWithImage } from './pageOnly/TextWithImage'
import { SchrijfstijlVoorbeelden } from './pageOnly/SchrijfstijlVoorbeelden'
import { Marketing } from './pageOnly/Marketing'
import { Dankwoord } from './pageOnly/Dankwoord'
import color from '/cssGlobal/color.css'

export function MedewerkersNl() {
  return (
    <Medewerkers
      language={{
        languageUrl: 'en',
        languageText: 'English',
      }}
      mainNav={[
        'Merkverhaal',
        'Opbouw',
        'Logo',
        'Kleuren',
        'Elementen',
        'Schrijfstijl',
        'Marketing',
      ]}
      merkverhaal={{
        heading: (
          <>
            Merkverhaal,
            <br />
            een traditie in
            <br />
            groei en vernieuwing.
          </>
        ),
        verhaal: (
          <>
            <p>
              Wij bewijzen al ruim 80 jaar dat je twee werelden kunt verenigen in
              één bedrijf. Door commercieel te floreren en tegelijkertijd een
              zeer sociaal beleid te voeren. Wij zijn integer en succesvol. Wij
              wonen in IJsselstein en handelen tot in Azië. We hebben metaal als
              business en zien mensen als de kern.
            </p>
            <p>
              Want inderdaad, Roba Metals bestaat vooral uit mensen. Loyale
              mensen, handen-uit-de-mouwen-stekers, blijvers, volhouders,
              doorduwers; ondernemers in loondienst, die vrijheid willen én
              aankunnen, omdat ze er de verantwoordelijkheid van kunnen dragen.
              Gedreven, maar zonder pretenties.
            </p>
            <p>
              Wij zijn succesvol, maar bescheiden. Té bescheiden, om eerlijk te
              zijn. Wij hebben een wereldreputatie, maar vooral in eigen kring.
              In een wereld vol overdrijving en schreeuwerigheid, zijn wij
              geneigd te fluisteren. Wij doen onszelf tekort. Roba Metals is het
              goed bewaarde geheim uit IJsselstein.
            </p>
            <p>
              Wij zijn geen voorvechter van alles wat nieuw en nog onbewezen is.
              Wij stappen in als de relevantie duidelijk is; als wij snappen hoe
              het bij ons past en hoe het ons verder brengt. Of dat een nieuwe
              kniplijn, een digitaal platform of een innovatie op het gebied van
              duurzaamheid is; het is onze traditie om altijd te zorgen voor
              groei en vernieuwing. Wij kijken vooruit, samen met de volgende
              generatie.
            </p>
          </>
        ),
      }}
      opbouw={{
        heading: 'Opbouw identiteit Roba Metals',
        text: (
          <>
            <p>
              Betrouwbaarheid, loyaliteit, flexibiliteit en traditie.
              Dit zijn kernwoorden die de identiteit van ROBA omschrijven. Zij
              vormen de bouwstenen van onze nieuwe merkpositionering. Hiermee
              beschrijven we wat het doel is van onze organisatie, welke rol wij
              vervullen voor onze klanten en wat ons leidend principe is.
            </p>
          </>
        ),
        imageLg: illustrations['piramideNLlarge'],
        imageSm: illustrations['piramideNLsmall'],
      }}
      logoSlogan={{
        heading: 'Logo & nieuwe slogan',
        text: (
          <>
            <p>
              We zijn erg enthousiast over onze nieuwe merkstrategie en
              merkverhaal. Onze identiteit toont dat wij een loyale partner zijn
              voor onze klanten in de metaal. Wij vinden dit zo’n krachtig
              statement, dat we kiezen om onze oude slogan ‘A passion for metal’
              los te laten. De passie blijft uiteraard bestaan, maar wij gaan
              verder met onze nieuwe slogan ‘Trusted partner in metal’.
            </p>
          </>
        ),
        image: images['briefpapier'],
      }}
      nieuweLogo={{
        robaLogo: illustrations['roba'],
        robaLogoHeading: 'Logo',
        robaLogoText: (
          <p>
            We gebruiken het logo op drie manieren. Het ROBA groene logo is onze
            meest toegepaste vorm. Vervolgens is het mogelijk om het witte ROBA
            logo toe te passen op een donkere achtergrond. Tenslotte gebruiken
            we het zwarte ROBA logo alleen als kleur geen optie is.
          </p>
        ),
        payoff: illustrations['payoff'],
        payoffHeading: 'Slogan',
        payoffText: (
          <p>
            De nieuwe slogan ligt ten grondslag aan wie wij zijn als
            organisatie. Hiermee benadrukken wij onze bijdrage aan onze
            klanten.
          </p>
        ),
        robaLogoPayoff: illustrations['robaPayoff'],
        robaLogoPayoffHeading: 'Gebruik logo & slogan',
        robaLogoPayoffText: (
          <p>
            Volgens de nieuwe richtlijnen communiceren wij de slogan niet altijd
            standaard samen met het logo. Dus schrik niet als het logo soms
            zelfstandig staat.
          </p>
        ),
      }}
      kleurgebruik={{
        heading: 'Kleurgebruik',
        text: (
          <p>
            ROBA is groen. De donkergroene kleur zit verweven in onze
            identiteit. Daarom blijft dit onze hoofdkleur, maar dan een
            tikkeltje verfrissender. We hebben nieuwe basiskleuren en twee
            accentkleuren toegevoegd aan het kleurenpalet.
          </p>
        ),
      }}
      colorBlocks={{
        groen: 'Donker groen',
        wit: 'Wit',
        lichtGrijs: 'Licht grijs',
        middenGrijs: 'Midden grijs',
        donkerGrijs: 'Donker grijs',
        zwart: 'Zwart',
        felGroen: 'Fel groen',
        oranje: 'Oranje',
      }}
      huisstijlElementen={{
        heading: 'Huisstijlelementen & iconen',
        text: (
          <p>
            Met onze nieuwe huisstijl geven wij Roba Metals een herkenbare,
            vernieuwde en eenduidige uitstraling die past bij onze identiteit.
            Wij voeren deze nieuwe huisstijl binnenkort door op diverse middelen
            binnen onze organisatie. Daarnaast werken wij druk aan onze nieuwe
            website.
          </p>
        ),
        image: images['brochures'],
      }}
      iconografie={{
        iconenHeading: 'Iconen',
        iconenText: (
          <p>
            Onze nieuwe iconografie maakt de afdelingen binnen ROBA in één oogopslag duidelijk.
            De iconen refereren naar een specifieke kenmerk van onze
            dienstverlening. Het icoon voor Staal is gebaseerd op een coil, RVS-
            en Aluminium op een stapel platen en Non-Ferro op een fitting. Onze
            Recycling divisie krijgt een algemeen herkenbaar icoon.
          </p>
        ),
        staal: illustrations['staal'],
        recycling: illustrations['recycling'],
        nonferro: illustrations['nonferro'],
        rvs: illustrations['rvs'],
      }}
      typografie={{
        corporateHeading: 'Corporate lettertype',
        corporateText: (
          <p>
            Voor de communicatie naar klanten hebben wij een speciaal lettertype: <strong>Maison Neue</strong>. Alleen de marketingafdeling heeft hiervoor een licentie. Dit lettertype ga je terugzien in o.a. onze nieuwe folders en op de website.
          </p>
        ),
        officeHeading: 'Office lettertype',
        officeText: (
          <p>
            De nieuwe standaard typografie van Roba Metals is <strong>Arial</strong>. Dit lettertype is voor iedereen toegankelijk en gebruiken we binnen onze werkomgeving. Wij veranderen documenten, PowerPoint en e-mails naar dit lettertype. Andere lettertypes zijn niet meer toegestaan.
          </p>
        ),
      }}
      schrijfstijl={{
        heading: 'Schrijfstijl',
        text: (
          <>
            <p>
              Wij hebben een handboek ontwikkeld als extra hulp om richting te geven in het schrijven van eenduidige teksten naar klanten. Deze tool is niet bedoeld om direct anders te communiceren naar klanten. Wij hebben met sommige klanten al jarenlang een goede en vertrouwde relatie. Gebruik je eigen inzichten en kennis van je relaties om hier een goede invulling aan te geven. Heb je hier interesse in dit handboek, dan kun je de schrijfwijzer opvragen bij Marketing. Hier volgen een aantal voorbeelden:
            </p>
          </>
        ),
        image: images['schrijfstijl'],
      }}
      schrijfstijlVoorbeelden={{
        voorbeeldHeading: 'Voorbeeld',
        welHeading: 'Wat betekent het wel',
        nietHeading: 'Wat betekent het niet',
        voorbeeldConcreetText: (
          <p>
            Het bieden van goede service en kwaliteit: daar staan we voor bij
            Roba Metals. Wij denken mee over inkoopmomenten, bieden korte
            levertijden en onafhankelijk advies. Betrouwbaar, zoals het hoort.
          </p>
        ),
        concreetHeading: 'Concreet',
        concreetWelText: (
          <p>
            Helder, to the point, beide benen op de grond, direct, daadkrachtig,
            zelfverzekerd, kundig, solide, zonder opsmuk, duidelijke taal,
            begrijpelijk, toegankelijke woordkeus.
          </p>
        ),
        concreetNietText: <p>Kortaf, droog, saai, afstandelijk, staccato.</p>,
        voorbeeldPersoonlijk: (
          <p>
            In ons werk draait alles om u en uw wensen. Bij Roba Metals werken wij als één team samen om te zorgen dat u tevreden bent: van de eerste kop koffie tot de levering.
          </p>
        ),
        persoonlijkHeading: 'Persoonlijk',
        persoonlijkWelText: (
          <p>
            Mensgericht, verbonden, verbindend, empathisch, hartelijk, warm,
            respectvol, sociaal, vriendelijk, open, verhalend.
          </p>
        ),
        persoonlijkNietText: (
          <p>
            Amicaal, onprofessioneel, onzakelijk, emotioneel, wollige taal,
            onnodige, voorzichtigheid, doelloos, familiair.
          </p>
        ),
        voorbeeldGedreven: (
          <p>
            ‘Wereldvreemde’ specialist, detailzucht, te serieus, vakjargon,
            schreeuwerig, opschepperig, belerend.
          </p>
        ),
        gedrevenHeading: 'Gedreven',
        gedrevenWelText: (
          <p>
            Ambitieus, gepassioneerd, positief, sterk, energiek, doelgericht,
            toekomstgericht, trots.
          </p>
        ),
        gedrevenNietText: (
          <p>
            ‘Wereldvreemde’ specialist, detailzucht, te serieus, vakjargon,
            schreeuwerig, opschepperig, belerend.
          </p>
        ),
      }}
      marketing={{
        heading: 'Nieuwe interne structuur: rol van marketingafdeling',
        lead: (
          <p>
            Wij zijn trots op onze nieuwe huisstijl en we willen deze consequent
            toepassen. Daarom is Marketing aangesteld als centraal punt binnen de ROBA divisie voor alle locaties voor dit onderwerp. Marketing bewaakt de huisstijl
            en helpt je om deze consequent toe te passen. Twijfel je over het
            gebruik van de huisstijl? Neem dan contact op met de marketingafdeling voor vragen of advies.
          </p>
        ),
        text: (
          <>
            <p>
              Alle externe communicatie-uitingen verlopen via de
              marketingafdeling. Je kunt hier denken aan advertenties, folders
              en beurzen. Verder helpt Marketing je onder andere met:
            </p>
            <ul>
              <li>
                Meedenken en advies geven over communicatie, ontwerpen en
                toepassen van de huisstijl.
              </li>
              <li>
                Lay-out technisch goedkeuring geven op content, documenten,
                PowerPoints, advertenties.
              </li>
              <li>
                Meehelpen in eenvoudige contentcreatie of het uitzetten van
                contentcreatie bij externe partijen (in overleg).
              </li>
              <li>
                Opzetten van advertenties in samenwerkingen met externe
                partijen.
              </li>
              <li>
                Marketingplan maken voor specifieke communicatiedoelstellingen
                binnen je afdeling.
              </li>
              <li>
                Doorplaatsen van content op de LinkedIn pagina en website.
              </li>
            </ul>
          </>
        ),
        image: images['vlag'],
      }}
      dankwoord={{
        heading: 'Dankwoord',
        text: (
          <>
            <p>
              Wij willen jullie ontzettend bedanken voor jullie geduld. Het
              heeft namelijk wel even geduurd totdat wij de huisstijl officieel
              konden onthullen. Wij hopen dat jullie enthousiast zijn over de
              nieuwe huisstijl! Onze speciale dank gaat uit naar de marketing
              teamleden die ons hebben geholpen de nieuwe huisstijl tot stand te
              brengen: Kendrick, Lucien, Danny Peele, Maarten Overbeek.
              Uiteraard ook de personen die aan de zijlijn hebben meegeholpen:
              Hanka, Jaco, Tom, Adrian en William.
            </p>
            <p>Contact en meer informatie:</p>
          </>
        ),
        person1Heading: 'Marjolein van der Bij',
        person1Text: (
          <>
            <p>
              Marketingafdeling
              <LinkBlock
                to={'mailto:mvdbij@robametals.com'}
                color={color.brightGreen}
              >
                mvdbij@robametals.com
              </LinkBlock>
            </p>
          </>
        ),
        person2Heading: 'Naomi Sapir',
        person2Text: (
          <>
            <p>
              Juridische- en marketingafdeling.
              <LinkBlock
                to={'mailto:nsapir@robametals.com'}
                color={color.brightGreen}
              >
                nsapir@robametals.com
              </LinkBlock>
            </p>
          </>
        ),
        person3Heading: 'Paul Cohn',
        person3Text: (
          <>
            <p>
              <LinkBlock
                to={'mailto:pcohn@robametals.com'}
                color={color.brightGreen}
              >
                pcohn@robametals.com
              </LinkBlock>
            </p>
          </>
        ),
        person4Heading: 'Pim van den Heuvel',
        person4Text: (
          <>
            <p>
              <LinkBlock
                to={'mailto:pvdheuvel@robametals.com'}
                color={color.brightGreen}
              >
                pvdheuvel@robametals.com
              </LinkBlock>
            </p>
          </>
        ),
      }}
    />
  )
}

export function MedewerkersEn() {
  return (
    <Medewerkers
      language={{
        languageUrl: 'nl',
        languageText: 'Nederlands',
      }}
      mainNav={[
        'Brand Story',
        'Structure',
        'Logo',
        'Colours',
        'Elements',
        'Tone of voice',
        'Marketing',
      ]}
      merkverhaal={{
        heading: (
          <>
            Brand story,
            <br />
            A tradition in
            <br />
            growth and innovation.
          </>
        ),
        verhaal: (
          <>
            <p>
              For over 80 years we have proved that two worlds can come together
              in one company. By prospering in the commercial field and being
              social at the same time. We are honest while being successful. We
              are based in IJsselstein and we do business worldwide. Metal is
              our core business and people are at the heart of the company.
            </p>
            <p>
              Indeed, at Roba Metals everything is about our people. Loyal
              people, hardworking people, long time employees, persistent
              people; entrepreneurs on the payroll having a lot of freedom
              because they know how to deal with that responsibility. Driven but
              without being pretentious.
            </p>
            <p>
              We are successful, but modest. To be honest: almost too modest. We
              are world renowned, but mainly in our own circle. In a world full
              of exaggeration we tend to whisper. We sell ourself short. Roba
              Metals is the best kept secret in IJsselstein.
            </p>
            <p>
              We are not a pioneer of things that have not yet been proved
              successful. We take part when things have become relevant and
              clear and when we understand how it can help us to get to the next
              level. Whether it is a new cut-to-length line, a digital platform
              or innovation in the field of sustainability. It is our tradition
              to ensure growth and stability. We are looking forward to a
              sustainable future for the next generation.
            </p>
          </>
        ),
      }}
      opbouw={{
        heading: 'The identity structure of Roba Metals',
        text: (
          <>
            <p>
              Trustworthy, loyal, flexibility and tradition are a few words that
              describe the identity of ROBA. These terms are the skeleton of our
              new brand positioning. With this, we describe the goal of our
              organisation, our added value for our customers and our key
              principle.
            </p>
          </>
        ),
        imageLg: illustrations['piramideENlarge'],
        imageSm: illustrations['piramideENsmall'],
      }}
      logoSlogan={{
        heading: 'Logo & new slogan',
        text: (
          <>
            <p>
              We are very excited about our new marketing strategy and brand
              story. Our identity shows that we are a loyal partner to our
              customers in metal. This is so important to us that we are ready
              to drop our old slogan ‘A passion for metal’. It speaks for itself
              that we will always have passion for metal. We have chosen for a
              new slogan: ‘Trusted partner in metal’.
            </p>
          </>
        ),
        image: images['briefpapier'],
      }}
      nieuweLogo={{
        robaLogo: illustrations['roba'],
        robaLogoHeading: 'Logo',
        robaLogoText: (
          <p>
            We use our logo in three different ways. Our logo in ROBA green is
            our standard way of communicating our logo. We can also use the
            white ROBA logo on a dark background and when colour is not an
            option we use the black ROBA logo.
          </p>
        ),
        payoff: illustrations['payoff'],
        payoffHeading: 'Slogan',
        payoffText: (
          <p>
            Our new slogan is at the heart of our organization. It shows our
            drive to help our customers to thrive in their business.
          </p>
        ),
        robaLogoPayoff: illustrations['robaPayoff'],
        robaLogoPayoffHeading: 'Use of logo & slogan',
        robaLogoPayoffText: (
          <p>
            It is no longer our standard to communicate our slogan together with
            our logo. So don’t be shocked when you see the logo without the new
            slogan.
          </p>
        ),
      }}
      kleurgebruik={{
        heading: 'Use of colour palette',
        text: (
          <p>
            ROBA is green. The dark green colour is part of our identity.
            Therefor this will remain our main colour, however we choose for a
            slightly fresher green. We added two new base colours and two accent
            colours to our colour palette.
          </p>
        ),
      }}
      colorBlocks={{
        groen: 'Dark Green',
        wit: 'White',
        lichtGrijs: 'Light grey',
        middenGrijs: 'Mid Grey',
        donkerGrijs: 'Dark grey',
        zwart: 'Black',
        felGroen: 'Bright green',
        oranje: 'Orange',
      }}
      huisstijlElementen={{
        heading: 'Corporate identity and symbols',
        text: (
          <p>
            Our new corporate identity is recognizable, innovative and
            unambiguous, which just perfectly suits our company. In the near
            future we will introduce our new corporate identity on all kind of
            means of communication. This includes a new website, we are
            currently working on.
          </p>
        ),
        image: images['brochures'],
      }}
      iconografie={{
        iconenHeading: 'Icons',
        iconenText: (
          <p>
            With these new created symbols it is possible to see all our
            divisions at a glance. The symbols refer to a specific
            characteristic of each of our divisions.The icon used for our Steel
            division is a coil, whereas the icon used for our Stainless steel-
            and Aluminium division is a stack of sheets and the icon for
            Non-Ferro is a fitting. Our Recycling division is indicated by a
            symbol that is generally known.
          </p>
        ),
        staal: illustrations['steel'],
        recycling: illustrations['recycling'],
        nonferro: illustrations['nonferrous'],
        rvs: illustrations['stainless'],
      }}
      typografie={{
        corporateHeading: 'Corporate font',
        corporateText: (
          <p>
            For communication to our customers we use a new special font: <strong>Maison
              Neue</strong>. Only our marketing department has a licence to use this font.
            You will find this font on our new brochures and website for
            example.
          </p>
        ),
        officeHeading: 'Font for office purposes',
        officeText: (
          <p>
            Our standard office font will be <strong>Arial</strong>. This font is available to
            everyone and we will use this for all our daily communication. This
            means we will change all kinds of documents, PowerPoint
            presentations, and e-mails to this font. It is no longer allowed to
            use other fonts.
          </p>
        ),
      }}
      schrijfstijl={{
        heading: 'Tone of voice',
        text: (
          <>
            <p>
              We have developed a handbook in order to give you some extra
              support when you are writing to customers. This tool is not meant
              to suddenly change the way we communicate with customers. With
              some of our customer we have a long and trusted relationship.
              Therefor we recommend to use your own judgement when communicating
              with customers and only use the handbook as a guidance.
            </p>
            <p>
              This handbook is based on the Dutch language. If you would like to
              receive this handbook, please contact our marketing department.
              Some examples:
            </p>
          </>
        ),
        image: images['schrijfstijl'],
      }}
      schrijfstijlVoorbeelden={{
        voorbeeldHeading: 'Example',
        welHeading: 'This means',
        nietHeading: 'This does not mean',
        voorbeeldConcreetText: (
          <p>
            Clear, to the point, down to earth, direct, decisive, confident,
            knowledgeable, decent, unadorned clear and accessible language,
            accessible choice of wording.
          </p>
        ),
        concreetHeading: 'Specific',
        concreetWelText: (
          <p>
            Roba Metals stands for excellent service and quality. We help our
            customers to find the right moment to purchase, we have short
            delivery times and we offer independent advise. We are trustworthy
            just as it should be.
          </p>
        ),
        concreetNietText: <p>Curt, bland, boring, arrogant, disconnected.</p>,
        voorbeeldPersoonlijk: (
          <p>
            Our work is all about you as a customer. At Roba Metals we are one
            team and together we make sure you stay satisfied: from the first
            cup of coffee when we meet to the delivery we make.
          </p>
        ),
        persoonlijkHeading: 'Personal',
        persoonlijkWelText: (
          <p>
            People minded, engaged, connected, emphatic, cheerful, warm,
            respectful, social, friendly, open, narrative.
          </p>
        ),
        persoonlijkNietText: (
          <p>
            Amicable, not professional, not business like, emotional, woolly
            language, unnecessary, cautious, without a purpose, too familiar.
          </p>
        ),
        voorbeeldGedreven: (
          <p>
            For over 80 years we are proud of metal. While our industry is
            constantly changing, at Roba Metals we remain experts. We follow all
            innovations and therefor you are assured of the best products,
            service and price.
          </p>
        ),
        gedrevenHeading: 'Driven',
        gedrevenWelText: (
          <p>
            Ambitious, passionate, positive, strong, energetic, goal oriented,
            forward looking, proud.
          </p>
        ),
        gedrevenNietText: (
          <p>
            A specialist from another planet, too many details, too serious,
            jargon, loud, arrogant, pedantic.
          </p>
        ),
      }}
      marketing={{
        heading: 'Our marketing department',
        lead: (
          <p>
            We are proud of our new corporate identity and we would like to use
            it in the right way. This why our marketing department has been
            appointed to handle all marketing related issues/questions for all
            companies within the ROBA division. Marketing makes sure that the
            corporate identity be used as it is meant to be. If you have any
            questions in this regard, please contact our marketing department.
            We are here to help.
          </p>
        ),
        text: (
          <>
            <p>
              Our marketing department is in charge of all external
              communication of our organization. For example, we can assist you
              with advertisements, brochures, flyers and fairs. We can also help
              with:
            </p>
            <ul>
              <li>
                Advice regarding to communication, design and the use of our
                corporate identity.
              </li>
              <li>
                Approval of the layout of content, documents, PowerPoints and
                advertisements.
              </li>
              <li>
                Assisting with content creation and finding third parties to
                help us with creating content.
              </li>
              <li>Drafting advertisement plans together with third parties.</li>
              <li>
                Create a marketing plan for specific communication goals within
                our different divisions.
              </li>
              <li>
                Managing our LinkedIn page and posting content on the website.
              </li>
            </ul>
          </>
        ),
        image: images['vlag'],
      }}
      dankwoord={{
        heading: 'Thank you',
        text: (
          <>
            <p>
              We would like to thank you for your patience. We do realize that
              it took a while before we were ready to disclose our new corporate
              identity. We hope that you are enthusiastic about our new brand
              style. A special thanks goes to our marketing team: Kendrick,
              Lucien, Danny Peele and Maarten Overbeek. We would also like to
              thank Hanka, Jaco, Tom, Adrian and William for their great help
              and critical views throughout the process.
            </p>
            <p>Contact and further information:</p>
          </>
        ),
        person1Heading: 'Marjolein van der Bij',
        person1Text: (
          <>
            <p>
              Marketing department
              <LinkBlock
                to={'mailto:mvdbij@robametals.com'}
                color={color.brightGreen}
              >
                mvdbij@robametals.com
              </LinkBlock>
            </p>
          </>
        ),
        person2Heading: 'Naomi Sapir',
        person2Text: (
          <>
            <p>
              Legal and Marketing department
              <LinkBlock
                to={'mailto:nsapir@robametals.com'}
                color={color.brightGreen}
              >
                nsapir@robametals.com
              </LinkBlock>
            </p>
          </>
        ),
        person3Heading: 'Paul Cohn',
        person3Text: (
          <>
            <p>
              <LinkBlock
                to={'mailto:pcohn@robametals.com'}
                color={color.brightGreen}
              >
                pcohn@robametals.com
              </LinkBlock>
            </p>
          </>
        ),
        person4Heading: 'Pim van den Heuvel',
        person4Text: (
          <>
            <p>
              <LinkBlock
                to={'mailto:pvdheuvel@robametals.com'}
                color={color.brightGreen}
              >
                pvdheuvel@robametals.com
              </LinkBlock>
            </p>
          </>
        ),
      }}
    />
  )
}

function Medewerkers({
  language,
  mainNav,
  merkverhaal,
  opbouw,
  logoSlogan,
  nieuweLogo,
  kleurgebruik,
  colorBlocks,
  huisstijlElementen,
  iconografie,
  typografie,
  schrijfstijl,
  schrijfstijlVoorbeelden,
  marketing,
  dankwoord,
}) {
  return (
    <Page {...{ mainNav, language }}>
      <GroenMetWit>
        <RobaLogo />
      </GroenMetWit>

      <GroenMetWit id={mainNav[0]}>
        <Merkverhaal {...merkverhaal} />
      </GroenMetWit>

      <Banner src={images['banner']} alt="" />

      <GrijsMetZwart id={mainNav[1]}>
        <Opbouw {...opbouw} />
      </GrijsMetZwart>

      <WitMetZwart id={mainNav[2]}>
        <TextWithImage {...logoSlogan} />
      </WitMetZwart>

      <WitMetZwart>
        <NieuweLogo {...nieuweLogo} />
      </WitMetZwart>

      <GroenMetWit id={mainNav[3]}>
        <KleurGebruik {...kleurgebruik} />
      </GroenMetWit>

      <ColorBlocks {...colorBlocks} />

      <WitMetZwart id={mainNav[4]}>
        <TextWithImage {...huisstijlElementen} />
      </WitMetZwart>

      <WitMetZwart>
        <Iconografie {...iconografie} />
        <Typografie {...typografie} />
      </WitMetZwart>

      <GrijsMetZwart id={mainNav[5]}>
        <TextWithImage {...schrijfstijl} />
      </GrijsMetZwart>

      <GrijsMetZwart>
        <SchrijfstijlVoorbeelden {...schrijfstijlVoorbeelden} />
      </GrijsMetZwart>

      <WitMetZwart id={mainNav[6]}>
        <Marketing {...marketing} />
      </WitMetZwart>

      <GroenMetWit>
        <Dankwoord {...dankwoord} />
      </GroenMetWit>

      <SiteFooter />
    </Page>
  )
}

function GroenMetWit({ id = undefined, children }) {
  return (
    <PageSection
      sectionProps={{
        color: color.white,
        backgroundColor: color.darkGreen,
        lightOnDark: true,
      }}
      {...{ children, id }}
    />
  )
}
function GrijsMetZwart({ id = undefined, children }) {
  return (
    <PageSection
      sectionProps={{
        color: color.black,
        backgroundColor: color.lightGrey,
      }}
      {...{ children, id }}
    />
  )
}
function WitMetZwart({ id = undefined, children }) {
  return (
    <PageSection
      sectionProps={{
        color: color.black,
        backgroundColor: color.white,
      }}
      {...{ children, id }}
    />
  )
}
