import { images, illustrations } from '/images'
import { RobaLogo } from '/components/RobaLogo'
import { SiteFooter } from '/components/SiteFooter'
import { LinkBlock } from '/components/Link'
import { Banner } from '/components/Banner'
import { Page, PageSection } from './Page'
import { Merkverhaal } from './pageOnly/Merkverhaal'
import { Opbouw } from './pageOnly/Opbouw'
import { NieuweLogo } from './pageOnly/NieuweLogo'
import { KleurGebruik } from './pageOnly/KleurGebruik'
import { ColorBlocks } from './pageOnly/ColorBlocks'
import { Iconografie } from './pageOnly/Iconografie'
import { TextWithImage } from './pageOnly/TextWithImage'
import { Dankwoord } from './pageOnly/Dankwoord'
import color from '/cssGlobal/color.css'

export function KlantenNl() {
  return (
    <Klanten
      language={{
        languageUrl: 'en',
        languageText: 'English',
      }}
      mainNav={['Merkverhaal', 'Opbouw', 'Logo', 'Kleuren', 'Elementen']}
      merkverhaal={{
        heading: (
          <>
            Merkverhaal,
            <br />
            een traditie in
            <br />
            groei en vernieuwing.
          </>
        ),
        verhaal: (
          <>
            <p>
              Wij bewijzen al ruim 80 jaar dat je twee werelden kunt verenigen
              in één bedrijf. Door commercieel te floreren en tegelijkertijd een
              zeer sociaal beleid te voeren. Wij zijn integer en succesvol. Wij
              wonen in IJsselstein en handelen tot in Azië. We hebben metaal als
              business en zien mensen als de kern.
            </p>
            <p>
              Want inderdaad, Roba Metals bestaat vooral uit mensen. Loyale
              mensen, handen-uit-de-mouwen-stekers, blijvers, volhouders,
              doorduwers; ondernemers in loondienst, die vrijheid willen én
              aankunnen, omdat ze er de verantwoordelijkheid van kunnen dragen.
              Gedreven, maar zonder pretenties.
            </p>
            <p>
              Wij zijn succesvol, maar bescheiden. Té bescheiden, om eerlijk te
              zijn. Wij hebben een wereldreputatie, maar vooral in eigen kring.
              In een wereld vol overdrijving en schreeuwerigheid, zijn wij
              geneigd te fluisteren. Wij doen onszelf tekort. Roba Metals is het
              goed bewaarde geheim uit IJsselstein.
            </p>
            <p>
              Wij zijn geen voorvechter van alles wat nieuw en nog onbewezen is.
              Wij stappen in als de relevantie duidelijk is; als wij snappen hoe
              het bij ons past en hoe het ons verder brengt. Of dat een nieuwe
              kniplijn, een digitaal platform of een innovatie op het gebied van
              duurzaamheid is; het is onze traditie om altijd te zorgen voor
              groei en vernieuwing. Wij kijken vooruit, samen met de volgende
              generatie.
            </p>
          </>
        ),
      }}
      opbouw={{
        heading: 'Opbouw identiteit Roba Metals',
        text: (
          <>
            <p>
              Betrouwbaarheid, loyaliteit, flexibiliteit en traditie. Dit zijn
              kernwoorden die de identiteit van ROBA omschrijven. Zij vormen de
              bouwstenen van onze nieuwe merkpositionering. Hiermee beschrijven
              we wat het doel is van onze organisatie, welke rol wij vervullen
              voor onze relaties en wat ons leidend principe is.
            </p>
          </>
        ),
        imageLg: illustrations['piramideNLlarge'],
        imageSm: illustrations['piramideNLsmall'],
      }}
      logoSlogan={{
        heading: 'Logo & nieuwe slogan',
        text: (
          <>
            <p>
              Wij zijn erg enthousiast over onze nieuwe merkstrategie en
              merkverhaal. Onze identiteit toont dat wij een loyale partner zijn
              voor onze relaties in de metaal. Wij vinden dit zo’n krachtig
              statement, dat wij kiezen om onze oude slogan ‘A passion for
              metal’ los te laten. De passie blijft uiteraard bestaan, maar wij
              gaan verder met onze nieuwe slogan: ‘Trusted partner in metal’.
            </p>
          </>
        ),
        image: images['briefpapier'],
      }}
      nieuweLogo={{
        robaLogo: illustrations['roba'],
        robaLogoHeading: 'Logo',
        robaLogoText: (
          <p>
            We gebruiken het logo op drie manieren. Het ROBA groene logo is onze
            meest toegepaste vorm. Vervolgens is het mogelijk om het witte ROBA
            logo toe te passen op een donkere achtergrond. Tenslotte gebruiken
            we het zwarte ROBA logo alleen als kleur geen optie is.
          </p>
        ),
        payoff: illustrations['payoff'],
        payoffHeading: 'Slogan',
        payoffText: (
          <p>
            De nieuwe slogan ligt ten grondslag aan wie wij zijn als
            organisatie. Hiermee benadrukken wij onze bijdrage aan onze
            relaties.
          </p>
        ),
        robaLogoPayoff: illustrations['robaPayoff'],
      }}
      kleurgebruik={{
        heading: 'Kleurgebruik',
        text: (
          <p>
            ROBA is groen. De donkergroene kleur zit verweven in onze
            identiteit. Daarom blijft dit onze hoofdkleur, maar dan een
            tikkeltje verfrissender. We hebben nieuwe basiskleuren en twee
            accentkleuren toegevoegd aan het kleurenpalet.
          </p>
        ),
      }}
      colorBlocks={{
        groen: 'Donker groen',
        wit: 'Wit',
        lichtGrijs: 'Licht grijs',
        middenGrijs: 'Midden grijs',
        donkerGrijs: 'Donker grijs',
        zwart: 'Zwart',
        felGroen: 'Fel groen',
        oranje: 'Oranje',
      }}
      huisstijlElementen={{
        heading: 'Huisstijlelementen & iconen',
        text: (
          <p>
            Met onze nieuwe huisstijl geven wij Roba Metals een herkenbare,
            vernieuwde en eenduidige uitstraling die past bij onze identiteit.
            Wij voeren deze nieuwe huisstijl binnenkort door op diverse middelen
            binnen onze organisatie. Daarnaast werken wij druk aan onze nieuwe
            website.
          </p>
        ),
        image: images['brochures'],
      }}
      iconografie={{
        iconenHeading: 'Iconen',
        iconenText: (
          <p>
            Onze nieuwe iconografie maakt de afdelingen binnen ROBA in één
            oogopslag duidelijk. De iconen refereren naar een specifiek kenmerk
            van onze dienstverlening. Het icoon voor Staal is gebaseerd op een
            coil, RVS- en Aluminium op een stapel platen en Non-Ferro op een
            fitting. Onze Recycling divisie krijgt een algemeen herkenbaar
            icoon.
          </p>
        ),
        staal: illustrations['staal'],
        recycling: illustrations['recycling'],
        nonferro: illustrations['nonferro'],
        rvs: illustrations['rvs'],
      }}
      dankwoord={{
        heading: 'Dankwoord',
        text: (
          <>
            <p>
              Namens alle medewerkers van Roba Metals bedanken wij u voor uw
              aandacht. Wij vinden het ontzettend leuk om onze nieuwe huisstijl
              met u te delen. Wij hopen dat u net zo enthousiast bent over deze
              nieuwe stijl als wij. Wij sturen een update als de vernieuwde Roba
              Metals website klaar is.
            </p>
            <p>
              Wij waarderen onze samenwerking en zien er naar uit deze in de
              toekomst voort te zetten.
            </p>
            <p>
              Als u in de tussentijd vragen hebt dan horen wij dat graag. U kunt
              hiervoor uiteraard altijd contact opnemen met uw vertrouwde
              accountmanager.
            </p>
            <p>Contact en meer informatie:</p>
          </>
        ),
        person1Heading: 'Marjolein van der Bij',
        person1Text: (
          <>
            <p>
              Marketingafdeling
              <LinkBlock
                to={'mailto:mvdbij@robametals.com'}
                color={color.brightGreen}
              >
                mvdbij@robametals.com
              </LinkBlock>
            </p>
          </>
        ),
      }}
    />
  )
}

export function KlantenEn() {
  return (
    <Klanten
      language={{
        languageUrl: 'nl',
        languageText: 'Nederlands',
      }}
      mainNav={['Brand Story', 'Structure', 'Logo', 'Colours', 'Elements']}
      merkverhaal={{
        heading: (
          <>
            Brand story,
            <br />
            A tradition in
            <br />
            growth and innovation.
          </>
        ),
        verhaal: (
          <>
            <p>
              For over 80 years we have proved that two worlds can come together
              in one company. By prospering in the commercial field and being
              social at the same time. We are honest while being successful. We
              are based in IJsselstein and we do business worldwide. Metal is
              our core business and people are at the heart of the company.
            </p>
            <p>
              Indeed, at Roba Metals everything is about our people. Loyal
              people, hardworking people, long time employees, persistent
              people; entrepreneurs on the payroll having a lot of freedom
              because they know how to deal with that responsibility. Driven but
              without being pretentious.
            </p>
            <p>
              We are successful, but modest. To be honest: almost too modest. We
              are world renowned, but mainly in our own circle. In a world full
              of exaggeration we tend to whisper. We sell ourself short. Roba
              Metals is the best kept secret in IJsselstein.
            </p>
            <p>
              We are not a pioneer of things that have not yet been proved
              successful. We take part when things have become relevant and
              clear and when we understand how it can help us to get to the next
              level. Whether it is a new cut-to-length line, a digital platform
              or innovation in the field of sustainability. It is our tradition
              to ensure growth and stability. We are looking forward to a
              sustainable future for the next generation.
            </p>
          </>
        ),
      }}
      opbouw={{
        heading: 'The identity structure of Roba Metals',
        text: (
          <>
            <p>
              Trustworthy, loyal, flexibility and tradition are a few words that
              describe the identity of ROBA. These terms are the skeleton of our
              new brand positioning. With this, we describe the goal of our
              organisation, our key principle and our added value for our
              customers and suppliers.
            </p>
          </>
        ),
        imageLg: illustrations['piramideENlarge'],
        imageSm: illustrations['piramideENsmall'],
      }}
      logoSlogan={{
        heading: 'Logo & new slogan',
        text: (
          <>
            <p>
              We are very excited about our new marketing strategy and brand
              story. Our identity shows that we are a loyal partner to our
              customers in metal. This is so important to us that we are ready
              to drop our old slogan ‘A passion for metal’. It speaks for itself
              that we will always have passion for metal. We have chosen for a
              new slogan: ‘Trusted partner in metal’.
            </p>
          </>
        ),
        image: images['briefpapier'],
      }}
      nieuweLogo={{
        robaLogo: illustrations['roba'],
        robaLogoHeading: 'Logo',
        robaLogoText: (
          <p>
            We use our logo in three different ways. Our logo in ROBA green is
            our standard way of communicating our logo. We can also use the
            white ROBA logo on a dark background and when colour is not an
            option we use the black ROBA logo.
          </p>
        ),
        payoff: illustrations['payoff'],
        payoffHeading: 'Slogan',
        payoffText: (
          <p>
            Our new slogan is at the heart of our organization. It shows our
            drive to help our customers and suppliers to thrive in their
            business.
          </p>
        ),
        robaLogoPayoff: illustrations['robaPayoff'],
      }}
      kleurgebruik={{
        heading: 'Use of colour palette',
        text: (
          <p>
            ROBA is green. The dark green colour is part of our identity.
            Therefore this will remain our main colour, however we choose for a
            slightly fresher green. We added two new base colours and two accent
            colours to our colour palette.
          </p>
        ),
      }}
      colorBlocks={{
        groen: 'Dark Green',
        wit: 'White',
        lichtGrijs: 'Light grey',
        middenGrijs: 'Mid grey',
        donkerGrijs: 'Dark grey',
        zwart: 'Black',
        felGroen: 'Bright green',
        oranje: 'Orange',
      }}
      huisstijlElementen={{
        heading: 'Corporate identity and symbols',
        text: (
          <p>
            Our new corporate identity is recognizable, innovative and
            unambiguous, which just perfectly suits our company. In the near
            future we will introduce our new corporate identity on all kind of
            means of communication. This includes a new website, we are
            currently working on.
          </p>
        ),
        image: images['brochures'],
      }}
      iconografie={{
        iconenHeading: 'Symbols',
        iconenText: (
          <p>
            With these new created symbols it is possible to see all our
            divisions at a glance. The symbols refer to a specific
            characteristic of each of our divisions. The icon used for our Steel
            division is a coil, whereas the icon used for our Stainless steel-
            and Aluminium division is a stack of sheets and the icon for
            Non-Ferrous is a fitting. Our Recycling division is indicated by a
            symbol that is generally known.
          </p>
        ),
        staal: illustrations['steel'],
        recycling: illustrations['recycling'],
        nonferro: illustrations['nonferrous'],
        rvs: illustrations['stainless'],
      }}
      dankwoord={{
        heading: 'Thank you',
        text: (
          <>
            <p>
              On behalf of all Roba Metals employees, we thank you for your
              attention. We are excited to share our new corporate identity with
              you and we hope that you like it as much as we do. We will send
              you an update when the renewed Roba Metals website is ready.
            </p>
            <p>
              We appreciate our cooperation and look forward to continuing it in
              the future.
            </p>
            <p>
              If you have any questions in the meantime, please don’t hesitate
              to contact us. Of course, you can always contact your trusted
              account manager.
            </p>
            <p>Contact and further information:</p>
          </>
        ),
        person1Heading: 'Marjolein van der Bij',
        person1Text: (
          <>
            <p>
              Marketing Department
              <LinkBlock
                to={'mailto:mvdbij@robametals.com'}
                color={color.brightGreen}
              >
                mvdbij@robametals.com
              </LinkBlock>
            </p>
          </>
        ),
      }}
    />
  )
}

function Klanten({
  language,
  mainNav,
  merkverhaal,
  opbouw,
  logoSlogan,
  nieuweLogo,
  kleurgebruik,
  colorBlocks,
  huisstijlElementen,
  iconografie,
  dankwoord,
}) {
  return (
    <Page {...{ mainNav, language }}>
      <GroenMetWit>
        <RobaLogo />
      </GroenMetWit>

      <GroenMetWit id={mainNav[0]}>
        <Merkverhaal {...merkverhaal} />
      </GroenMetWit>

      <Banner src={images['banner']} alt="" />

      <GrijsMetZwart id={mainNav[1]}>
        <Opbouw {...opbouw} />
      </GrijsMetZwart>

      <WitMetZwart id={mainNav[2]}>
        <TextWithImage {...logoSlogan} />
      </WitMetZwart>

      <WitMetZwart>
        <NieuweLogo {...nieuweLogo} />
      </WitMetZwart>

      <GroenMetWit id={mainNav[3]}>
        <KleurGebruik {...kleurgebruik} />
      </GroenMetWit>

      <ColorBlocks {...colorBlocks} />

      <WitMetZwart id={mainNav[4]}>
        <TextWithImage {...huisstijlElementen} />
      </WitMetZwart>

      <WitMetZwart>
        <Iconografie {...iconografie} />
      </WitMetZwart>

      <GroenMetWit>
        <Dankwoord {...dankwoord} />
      </GroenMetWit>

      <SiteFooter />
    </Page>
  )
}

function GroenMetWit({ id = undefined, children }) {
  return (
    <PageSection
      sectionProps={{
        color: color.white,
        backgroundColor: color.darkGreen,
        lightOnDark: true,
      }}
      {...{ children, id }}
    />
  )
}
function GrijsMetZwart({ id = undefined, children }) {
  return (
    <PageSection
      sectionProps={{
        color: color.black,
        backgroundColor: color.lightGrey,
      }}
      {...{ children, id }}
    />
  )
}
function WitMetZwart({ id = undefined, children }) {
  return (
    <PageSection
      sectionProps={{
        color: color.black,
        backgroundColor: color.white,
      }}
      {...{ children, id }}
    />
  )
}
