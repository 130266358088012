import styles from './Link.css'

export function LinkInline({ children, to = undefined, color = undefined }) {
  return <LinkBase
    className={cx(styles.componentInline)}
    {...{ children, to, color }}
  />
}

export function LinkBlock({ children, to = undefined, color = undefined }) {
  return <LinkBase
    className={cx(styles.componentBlock)}
    {...{ children, to, color }}
  />
}

function LinkBase({ className: clickableClassName, to, children, color }) {
  const className = cx(styles.componentBase, clickableClassName )
  return (
    <a href={to} {...{ className }} style={{ color }}>
      {children}
    </a>
  )
}
