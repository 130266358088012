import { TextWithHeading } from '/components/TextWithHeading'
import { CenteredContainer } from '/components/CenteredContainer'
import { HeadingMd } from '/components/Heading'
import { TextSm } from '/components/Text'
import { Row } from '/components/Row'
import { GridTwoColumns } from '/components/GridTwoColumns'

export function Iconografie({
  iconenHeading,
  iconenText,
  staal,
  recycling,
  nonferro,
  rvs
}) {
  return (
    <CenteredContainer size="xl">
      <Row
        description={
          <TextWithHeading
            heading={<HeadingMd>{iconenHeading}</HeadingMd>}
            text={<TextSm>{iconenText}</TextSm>}
          />
        }
        content={
          <GridTwoColumns alignCenter>
            <img src={staal} alt="" />
            <img src={recycling} alt="" />
            <img src={nonferro} alt="" />
            <img src={rvs} alt="" />
          </GridTwoColumns>
        }
      />
    </CenteredContainer>
  )
}
