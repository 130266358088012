import { TextWithHeading } from '/components/TextWithHeading'
import { CenteredContainer } from '/components/CenteredContainer'
import { HeadingXl } from '/components/Heading'
import { TextMd } from '/components/Text'
import styles from './KleurGebruik.css'

export function KleurGebruik({ heading, text }) {
  return (
    <CenteredContainer size="xl">
      <article className={styles.component}>
        <TextWithHeading
          layoutClassName={styles.text}
          heading={<HeadingXl>{heading}</HeadingXl>}
          text={<TextMd>{text}</TextMd>}
        />
      </article>
    </CenteredContainer>
  )
}
