import { useTransition, animated } from 'react-spring'
import lottie from 'lottie-web/build/player/lottie_light'
import { LinkInline } from '/components/Link'
import animationData from '/lotties/logoReveal.json'
import styles from './IntroAnimation.css'

export function IntroAnimation({ layoutClassName }) {
  const [showAnimation, setAnimation] = React.useState(true)
  const animationRef = React.useRef()
  const transition = useTransition(showAnimation, {
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  React.useEffect(() => {
    const animation = lottie.loadAnimation({
      container: animationRef.current,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData,
    })

    animation.addEventListener('complete', () => setAnimation(false))
    return () => { animation.destroy() }
  }, [])

  return (
    <>
      {transition(( props, item ) =>
        item && (
          <animated.div
            style={props}
            className={cx(styles.animationWrapper, layoutClassName)}
          >
            <div className={styles.animation} ref={animationRef} />
            <div
              className={styles.skipButton}
              onClick={() => setAnimation(false)}
            >
              <LinkInline>Skip intro</LinkInline>
            </div>
          </animated.div>
        ))}
    </>
  )
}
